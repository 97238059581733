import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import { isAfterInicioConsumoServicosImpressaoGrafica } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Chip from "@material-ui/core/Chip";

import TipoDocumentoService from "../../services/TipoDocumento";
import CoordenadorService from "../../services/Coordenador";
import { errors } from '../../services/API'

import Toast from '../../components/Toast/Toast'
import Text from "../../components/Inputs/Text/Text";
import ButtonsForm from '../../forms/Buttons/ButtonsForm'
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from "../../components/Title/Title";
import Loading from "../../components/Loading/Loading";

import { Layout } from '../../layouts/private/Private';

import { filterPersistence, locationStateParse } from '../../helper/ParseStateHelper';
import { numberMask } from "../../helper/MaskHelper";

import { patternMateriais } from "../../variables/Enums/MaterialExtra";

export class CoordenadorMaterialExtra extends Component {
    constructor(props) {
        super(props);
        this.state = {
            coordenadores: [],
            tipos: [],
            materiais: [],
            loading: false,
            loadingButtonSave: false,
            history: {
                path: null,
                state: null
            }
        }
    }

    // DECLARAÇÕES DE REFERENCIA DOS COMPONENTES
    setToast = t => this.Toast = t;

    handleChange = e => {
        this.setState({
            materiais: [...this.state.materiais.filter(m => {
                if (m.id === parseInt(e.target.name))
                    m.value = numberMask(e.target.value) !== "" ? parseInt(e.target.value) : 0;
                return true;
            })],
        });
    };

    handleBack = () => {
        this.setState({
            history: {
                ...this.state.history,
                state: {
                    ...this.state.history.state,
                    checksCoords: [],
                }
            }
        }, () => {
            this.props.history.push({
                pathname: `${this.state.history.path}`,
                state: {
                    history: {
                        state: this.state.history.state,
                    }
                }
            });
        });
    }

    // SUBMIT
    handleSubmit = () => {
        this.setState({ loadingButtonSave: true });

        const objeto = {
            coordenadores: [
                ...this.state.coordenadores.map(c => c.id)
            ],
            materiais: [
                ...this.state.materiais.map(m => {
                    return { cdTipoDocumento: m.id, qtdDocumento: m.value ?? 0 };
                })
            ],
        };

        CoordenadorService.atualizarMaterialExtra(objeto)
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: "Quantidade de Material Extra Atualizada Com Sucesso.",
                        type: "success",
                        open: true
                    }
                })

                this.setState({ loadingButtonSave: false });
                setTimeout(() => this.handleBack(), 500);
            })
            .catch(err => {
                const e = errors(err);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.setState({ loadingButtonSave: false }));
    }

    async componentDidMount() {
        this.props.loadContext();

        const location = this.props.location;
        this.setState({ ...locationStateParse(location), loading: true, history: filterPersistence(location) });

        await TipoDocumentoService.filters([{ field: "materialExtra", value: true }])
            .then(res => this.setState({ tipos: [...res.data] }));

        if (this.state.coordenadores.length === 1) {
            CoordenadorService.buscarMaterialExtra(this.state.coordenadores[0].id)
                .then(res => {
                    this.setState({
                        materiais: [...patternMateriais(this.state.tipos, res.data)]
                    })
                })
                .catch(err => {
                    this.setState({
                        materiais: [...patternMateriais(this.state.tipos, [])]
                    })

                    const e = errors(err);
                    this.Toast.setState({
                        message: {
                            message: "Não Foi Possível Buscar os Materiais Extras deste Coordenador",
                            type: e.type,
                            open: true
                        }
                    })
                })
                .finally(() => this.setState({ loading: false }));
        } else {
            this.setState({
                loading: false,
                materiais: [...patternMateriais(this.state.tipos, [])]
            })
        }
    }

    render() {
        const pageName = 'Gerenciar Quantidade de Material Extra';
        const links = [
            {
                path: null,
                name: 'Administração'
            },
            {
                path: "/admin/coordenadores",
                name: 'Coordenadores'
            }
        ];

        return (
            <Layout>
                <Toast parentRef={this.setToast} />
                <Grid container >
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={pageName} />
                    </Grid>
                </Grid>
                <Title>{pageName}</Title>

                { this.state.loading ?
                    <Loading />
                :
                    <>
                        <Grid container alignItems='center'>
                            <Grid item sm={12} lg={12} style={{ marginBottom: 16 }} >
                                <Paper elevation={1} style={{ padding: 16 }} >
                                    <Typography component="p" variant="overline" >
                                        {this.state.coordenadores.length > 1 ? "Coordenadores Selecionados" : "Coordenador Selecionado"}:
                                    </Typography>

                                    {this.state.coordenadores.map((c) =>
                                        <div key={c.id} style={{ margin: "4px", display: "inline-block" }}>
                                            <Chip
                                                color="primary"
                                                label={`${c.login.toUpperCase()} - ${c.nome} - ${c.regiao}`}
                                            />
                                        </div>
                                    )}
                                </Paper>
                            </Grid>

                            <Typography component="span" variant="body2" >
                                <b>Atenção:</b>

                                <ul style={{ marginTop: 8, paddingLeft: 36 }}>
                                    <li>Informe abaixo as <b>quantidades adequadas</b> para cada tipo de Material Extra que deverá ser disponibilizada para envio {this.state.coordenadores.length > 1 ? "aos Coordenadores" : "ao Coordenador"}.</li>
                                    <li>Para os campos <b>vazios</b>, será atribuída quantidade <b>0</b> ao Material Extra</li>
                                    <li>Os campos vazios ou com quantidade <b>0</b> estarão destacado para sinalização de preenchimento, porém permanecem como <b>não obrigatórios</b>, podendo ser mantidos zerados para {this.state.coordenadores.length > 1 ? "os Coordenadores selecionados" : "os Coordenador selecionado"}.</li>
                                    { this.state.coordenadores.length > 1 &&
                                        <li>Caso algum dos Coordenadores Selecionado já tenha Material Extra definido, este Material será <b>sobrescrito</b> pela quantidade atribuída neste momento, sendo 0 caso o campo esteja vazio.</li>
                                    }
                                </ul>
                            </Typography>

                            <Grid container item sm={12} lg={12} style={{ marginTop: 16 }} spacing={3}>
                                {this.state.tipos.map(t =>
                                    <Grid key={t.codigoDocumento} item sm={12} lg={2} >
                                        <Text
                                            name={`${t.codigoDocumento}`}
                                            label={`#${t.codigoDocumento} - ${t.nomeTipoDocumento}`}
                                            onChange={(e) =>this.handleChange(e)}
                                            value={this.state.materiais.find(m => m.id === t.codigoDocumento).value}
                                            helperText={`Envelope: ${t.nomeEnvelope}`}
                                            error={this.state.materiais.find(m => m.id === t.codigoDocumento).value === 0}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>

                        <ButtonsForm
                            onClick={this.handleSubmit}
                            onBack={() => this.handleBack()}
                            idFocus={`saveButton`}
                            loading={this.state.loadingButtonSave}
                            saveDisabled={isAfterInicioConsumoServicosImpressaoGrafica(this.props.contexts)}
                        />
                    </>
                }
            </Layout>
        )
    }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CoordenadorMaterialExtra));