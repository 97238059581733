import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Avatar from '@material-ui/core/Avatar';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(14),
        flexBasis: '33.33%',
        flexShrink: 0,
        marginLeft: '12px',
        alignSelf: 'center',
    },
}));

export const Categoria = ({ children, nome, icone }) => {
    const classes = useStyles();

    const [ expanded, setExpanded ] = useState(true);

    return (
        <Grid item container spacing={3}>
            <Grid item sm={12} lg={12} >
                <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        {icone && <Avatar variant="rounded">{icone}</Avatar>}
                        <Typography variant='overline' className={classes.heading} gutterBottom>{nome}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {children}
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    );
}

export default Categoria;